<template>
  <v-container class="fill-height" fluid v-resize="onResize">
    <!-- <v-btn @click="DialogKetikaLogin = true"></v-btn> -->
    <!-- <v-dialog
      v-model="DialogKetikaLogin"
      max-width="270"
    >
      <v-card outlined rounded="lg" class="pa-2">
        <v-row
          no-gutters
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-col cols="12" class="title text-center">Terhubung Ke TSS</v-col>
          <v-col cols="12">
            <v-progress-linear
              color="deep-purple accent-4"
              rounded
              height="10"
              v-model="value"
              :active="show"
              :indeterminate="query"
              :query="true"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog> -->

    <!-- <v-row justify="center" v-show="mobile == false">        
      <v-card
        outlined
        width="350"
        class="elevation-3 rounded-lg"
      >
      <v-alert type="error" v-text="pesan" v-show="pesan.length != 0"/>
      <v-progress-linear
        color="red darken-4"
        class="rounded-t-lg"
        indeterminate
        height="20"
        v-show="loading"
      ></v-progress-linear>
        <v-layout justify-center>
          <v-list>
            <v-list-item class="px-2 mx-4 mb-n3 mt-4">
              <v-list-item-content>
                <v-img
                  max-width="50"
                  max-height="150px"
                  src="../assets/LogoTrinity.png"
                ></v-img>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">
                  <label>Masuk</label>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-layout>

        <v-card-text class="mt-n10">
          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-subheader class="mx-n4 body-1"><label>Nama</label></v-subheader>
            <v-text-field
              dense
              outlined
              color="red darken-4"
              class="form-control rounded-lg mt-n2"
              v-model="credentials.Nama"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="12"
          >
            <v-subheader class="mx-n4 mt-n10 body-1"><label>Kata Sandi</label></v-subheader>
            <v-text-field
              dense
              outlined
              color="red darken-4"
              class="form-control rounded-lg mt-n2"
              v-model="credentials.password"
              :append-icon="TampilkanSandi ? 'mdi-eye' : 'mdi-eye-off'"
              :type="TampilkanSandi ? 'text' : 'password'"
              @click:append="TampilkanSandi = !TampilkanSandi"
              @keyup.enter="login()"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-btn
              block
              dark
              color="red darken-4"
              class="btn btn-primary rounded-lg text-capitalize mt-n6"
              @click="login()"
            >
              Masuk
            </v-btn>
          </v-col>

          <v-layout justify-center>
            <label>
              Belum punya akun? Klik 
              <router-link
                to="/Register"
                class="red--text text--darken-4 text-decoration-none"
              >
                Daftar
              </router-link>
            </label>
          </v-layout>
        </v-card-text>
      </v-card> 
    </v-row> -->
    
    <!-- Login Mode Desktop Start -->
    <v-col cols="12" v-show="mobile == false" class="mt-n6">
      <div style="margin: auto; max-width: 350px;">
      <v-row no-gutters>
        <span class="title text-h5">Selamat Datang di TSS</span>
      </v-row>

      <v-row no-gutters class="mb-10 mt-n1 subtitle-1">
        <span>Trinity Support System</span>
      </v-row>

      <div>
        <span class="subtitle-1" :class="pesan.length != 0 ? 'error--text' : ''">Nama Pengguna</span>
        <v-text-field
          color="dark"
          single-line
          :error="pesan.length != 0"
          :disabled="loading == true"
          class="form-control mt-n4 body-1"
          v-model="credentials.Nama"
        ></v-text-field>
      </div>

      <div>
        <span class="subtitle-1" :class="pesan.length != 0 ? 'error--text' : ''">Kata Sandi</span>
        <v-text-field
          color="dark"
          single-line
          :error="pesan.length != 0"
          :disabled="loading == true"
          class="form-control mt-n4 mb-4 body-1"
          v-model="credentials.password"
          :append-icon="TampilkanSandi ? 'mdi-eye' : 'mdi-eye-off'"
          :type="TampilkanSandi ? 'text' : 'password'"
          @click:append="TampilkanSandi = !TampilkanSandi"
          @keyup.enter="login()"
        ></v-text-field>
      </div>

      <div>
        <v-btn
          block
          :loading="loading == true"
          :disabled="loading == true"
          depressed
          height="50px"
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          class="rounded-lg"
          @click="login()"
        >
          Login
        </v-btn>
      </div>

      <v-snackbar rounded="lg" v-model="alertpesan" v-show="pesan.length != 0 && mobile == false" :timeout="-1" :value="true" color="error">
        <v-icon left>mdi-alert-octagon-outline</v-icon>
        <span class="subtitle-1" v-text="pesan"></span>
      </v-snackbar>

      <!-- <div v-show="pesan.length != 0">
        <v-alert
          text
          color="error"
          class="rounded-lg mt-3"
          icon="mdi-alert-circle-outline"
        >
          <span v-text="pesan"></span>
        </v-alert>
      </div> -->

      <!-- <div class="mt-3">
        <v-btn @click="DialogLoginMobile = true" block depressed height="50px" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">Login</v-btn>
      </div>
      <div class="mt-3">
        <v-btn @click="register" block depressed height="50px" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">Register</v-btn>
      </div> -->
      <div>
        <v-img
          class="mx-auto mt-10"
          width="180px"
          src="../assets/TA_Logo.png"
        ></v-img>
      </div>
      </div>
    </v-col>
    <!-- Login Mode Desktop End -->

    <!-- Login Mode Mobile Start -->
    <v-col cols="12" v-show="mobile == true" class="mt-n16">
      <v-row no-gutters>
        <span class="title text-h5">Selamat Datang di TSS</span>
      </v-row>

      <v-row no-gutters class="mb-10 mt-n1 subtitle-1">
        <span>Trinity Support System</span>
      </v-row>

      <div>
        <span class="subtitle-1">Nama Pengguna</span>
        <v-text-field
          color="dark"
          single-line
          :disabled="loading == true"
          class="form-control mt-n4 body-1"
          v-model="credentials.Nama"
        ></v-text-field>
      </div>

      <div>
        <span class="subtitle-1">Kata Sandi</span>
        <v-text-field
          color="dark"
          single-line
          :disabled="loading == true"
          class="form-control mt-n4 mb-4 body-1"
          v-model="credentials.password"
          :append-icon="TampilkanSandi ? 'mdi-eye' : 'mdi-eye-off'"
          :type="TampilkanSandi ? 'text' : 'password'"
          @click:append="TampilkanSandi = !TampilkanSandi"
          @keyup.enter="login()"
        ></v-text-field>
      </div>

      <div>
        <v-btn
          block
          :loading="loading == true"
          :disabled="loading == true"
          depressed
          height="50px"
          :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
          class="rounded-lg"
          @click="login()"
        >
          Login
        </v-btn>
      </div>

      <div v-show="pesan.length != 0">
        <v-alert
          text
          color="error"
          class="rounded-lg mt-3"
          icon="mdi-alert-circle-outline"
        >
          <span v-text="pesan"></span>
        </v-alert>
      </div>

      <!-- <div class="mt-3">
        <v-btn @click="DialogLoginMobile = true" block depressed height="50px" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">Login</v-btn>
      </div>
      <div class="mt-3">
        <v-btn @click="register" block depressed height="50px" class="rounded-lg" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">Register</v-btn>
      </div> -->
      <div>
        <v-img
          class="mx-auto mt-10"
          width="180px"
          src="../assets/TA_Logo.png"
        ></v-img>
      </div>
    </v-col>
    <!-- Login Mode Mobile End -->

    <!-- <v-footer padless app v-show="mobile == false">
      <v-col
        class="text-center"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <span>Trinity Support System</span>
      </v-col>
    </v-footer> -->

    

    <!-- Dialog Login Mode Mobile -->
    <!-- <v-dialog
      v-model="DialogLoginMobile"
      fullscreen
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card
        class="tile"
        :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
      >
        <v-card-actions
          style="height: 64px;"
        >
          <v-btn icon class="ml-n3 mr-1" height="47px" width="47px" @click="DialogLoginMobile = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title class="title">Login</v-toolbar-title>
        </v-card-actions>

        <v-divider></v-divider>
        
        <v-card-text
          class="ScrollCSSHide"
        >
          <v-sheet
            class="mt-3"
          >
            <div class="title">
              <span>Selamat Datang Kembali!</span>
            </div>

            <div class="mb-10 mt-n1 subtitle-1">
              <span>Trinity Support System</span>
            </div>

            <div>
              <span class="subtitle-1">Nama Pengguna</span>
              <v-text-field
                color="dark"
                single-line
                :disabled="loading == true"
                class="form-control mt-n4"
                v-model="credentials.Nama"
              ></v-text-field>
            </div>

            <div>
              <span class="subtitle-1">Kata Sandi</span>
              <v-text-field
                color="dark"
                single-line
                :disabled="loading == true"
                class="form-control mt-n4 mb-4"
                v-model="credentials.password"
                :append-icon="TampilkanSandi ? 'mdi-eye' : 'mdi-eye-off'"
                :type="TampilkanSandi ? 'text' : 'password'"
                @click:append="TampilkanSandi = !TampilkanSandi"
                @keyup.enter="login()"
              ></v-text-field>
            </div>

            <div>
              <v-btn
                block
                :loading="loading == true"
                :disabled="loading == true"
                depressed
                height="50px"
                :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                class="rounded-lg"
                @click="login()"
              >
                Login
              </v-btn>
            </div>

            <div v-show="pesan.length != 0">
              <v-alert
                text
                color="error"
                class="rounded-lg mt-3"
                icon="mdi-alert-circle-outline"
              >
                <span v-text="pesan"></span>
              </v-alert>
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- Dialog Register Mode Mobile -->
    <Register v-show="DialogRegisterMobile == true" :DialogRegisterMobile="DialogRegisterMobile"/>

    <!-- <v-footer padless color="transparent" app>
      <v-col
        class="text-center caption"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Trinity Auto</strong>
      </v-col>
    </v-footer> -->
  </v-container>
</template>

<script>
// import Vue from 'vue';
import api from "@/services/http";
import Register from '@/components/Register';
export default {
  components: {
    Register,
  },

  data: () => ({
    alertpesan: true,
    value: 0,
    query: false,
    show: true,
    interval: 0,
    DialogKetikaLogin: false,
    DialogLoginMobile: false,
    DialogRegisterMobile: false,
    mobile:null,
    windowSize: {x: 0, y: 0},
    loading:false,
    TampilkanSandi: false,
    pesan:'',
    credentials:{
      Nama: '',
      password: ''
    },

    return:{
      loading: true
    },     
  }),

  mounted(){
    this.queryAndIndeterminate()
    // console.log(api.defaults.baseURL)
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  watch: {
    windowSize(){
      if (this.windowSize.x < 500) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },
  },

  methods: {
    register() {
      this.DialogRegisterMobile = true
    },

    queryAndIndeterminate () {
      this.query = true
      this.show = true
      this.value = 0

      setTimeout(() => {
        this.query = false

        this.interval = setInterval(() => {
          if (this.value === 100) {
            clearInterval(this.interval)
            this.show = true
            return setTimeout(this.queryAndIndeterminate, 3000)
          }
          this.value += 25
        }, 500)
      }, 300)
    },

    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },

    checkFlag() {
      if(this.$menu == undefined) {
        var cek = window.setTimeout(this.checkFlag, 100); /* this checks the flag every 100 milliseconds*/
      } else {
        localStorage.setItem('Menu',JSON.stringify(this.$menu))
        this.$router.push('/')
        clearTimeout(cek)
      }
    },

    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      showCloseButton: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },

    async login(){
     this.pesan = ''
     this.alertpesan = true
      this.loading = true
      api.post('/login', this.credentials)
        .then(res=>{
          if (res.data.status == false) {
          this.loading = false
          this.pesan = "Nama Pengguna atau Kata Sandi Mungkin Salah!"  
          }else if(res.data.status == "Akun Non Aktif"){
            this.loading = false
            this.pesan = "Anda Bisa Aktifasi Akun Terlebih Dahulu"
          }else {
            this.Toast('Terhubung Dengan TSS')
            this.DialogKetikaLogin = true
            setTimeout(() => {
              this.DialogKetikaLogin = false
            }, 4000);
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('user', JSON.stringify(res.data.user))
            //triger ke appbar via root dan emit
            this.checkFlag();
            this.$root.$emit('menuappbar')
            sessionStorage.setItem('greeting',0)
            //   const menu = async () => {
            //   const menulist = await this.$menu
            //   localStorage.setItem('Menu',JSON.stringify(menulist))
            //   console.log('ok :::',menulist);
            // }
              
              // while(this.$menu == undefined) {
              //   console.log('aww')
              //   }
            // const PromiseMenu = new Promise((resolve) => {
            //   setTimeout(resolve, 500, this.$menu);
            // });
            // var PromiseMenu = new Promise((resolve) => {
            //         resolve(this.$menu);
            //   });
            // Promise.all([PromiseMenu]).then((values) => {
            //   console.log('prm',values[0])
            //   // localStorage.setItem('Menu',JSON.stringify(values[0]))
            //   // this.$router.push('/')
            // });
            // var isfinished
            // var timeout = setInterval(function() {
            //   isfinished = false
            //       if(this.$menu.length > 0 ) {
            //           clearInterval(timeout); 
            //           localStorage.setItem('Menu',JSON.stringify(this.$menu))
            //           isfinished = true;
            //       }
            //   }, 100);
            // console.log(res)
          }
        })
        .catch(err=>{
          if (err == "Error: Request failed with status code 500") {
            this.loading = false
            alert('koneksi database bermasalah')
          }
          // alert('email atau password salah')
          // confirm('email atau password salah')
          console.log('email atau password salah')
        })
    },
  },
};

</script>

<style>

</style>